@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url('/static/media/fa-solid-900.eot');
    src: url('/static/media/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('/static/media/fa-solid-900.woff2') format('woff2'),
    url('/static/media/fa-solid-900.woff') format('woff'),
    url('/static/media/fa-solid-900.ttf') format('truetype'),
    url('/static/media/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.fa,
.fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fa-carrot:before {
    content: "\f787";
}

.fa-caret-up:before {
    content: '\f0d8';
}

.fa-caret-left:before {
    content: '\f0d9';
}

.fa-caret-right:before {
    content: '\f0da';
}

.fa-caret-down:before {
    content: '\f0d7';
}

.fa-angle-right:before {
    content: '\f105';
}

.fa-angle-down:before {
    content: "\f107";
}

.fa-eye:before {
    content: "\f06e";
}

.fa-eye-slash:before {
    content: "\f070";
}

.fa-arrow-right:before {
    content: "\f061";
}

.fa-question-circle:before {
    content: "\f059";
}


.fa-download:before {
    content: "\f019";
}

.fa-times:before {
    content: "\f00d";
}

.fa-clock-o:before {
    content: "\f017"
}

.fa-exclamation-circle:before {
    content: "\f06a"
}

.fa-plus:before {
    content: '\f067';
}

.fa-lock:before {
    content: '\f023';
}

.fa-angle-up:before {
    content: '\f106';
}
