/* build from online tool : https://bootstrap.build/app */
.btn-pink {
  color: #fff;
  background-color: #ef3f78;
  border-color: #ef3f78;
}
.btn-pink:hover {
  color: #fff;
  background-color: #ec1c5f;
  border-color: #e81358;
}
.btn-pink:focus,
.btn-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 92, 140, 0.5);
}
.btn-pink.disabled,
.btn-pink:disabled {
  cursor: no-drop;
  color: #fff;
  background-color: #ef3f78;
  border-color: #ef3f78;
}
.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #e81358;
  border-color: #dc1254;
}
.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 92, 140, 0.5);
}

.btn-outline-pink {
  color: #ef3f78;
  border-color: #ef3f78;
}
.btn-outline-pink:hover {
  color: #fff;
  background-color: #ef3f78;
  border-color: #ef3f78;
}
.btn-outline-pink:focus,
.btn-outline-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 63, 120, 0.5);
}
.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
  color: #ef3f78;
  background-color: transparent;
}
.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #ef3f78;
  border-color: #ef3f78;
}
.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 63, 120, 0.5);
}

.badge-pink {
  color: #fff;
  background-color: #ef3f78;
}
a.badge-pink:hover,
a.badge-pink:focus {
  color: #fff;
  background-color: #e81358;
}
a.badge-pink:focus,
a.badge-pink.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 63, 120, 0.5);
}

.bg-pink {
  background-color: #ef3f78 !important;
}

a.bg-pink:hover,
a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #e81358 !important;
}

.border-pink {
  border-color: #ef3f78 !important;
}

.text-pink {
  color: #ef3f78 !important;
}

a.text-pink:hover,
a.text-pink:focus {
  color: #d0114f !important;
}

/* references: bootstrap-4.3.1 class: custom-select */
.custom-react-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-react-select:focus,
.custom-react-select:focus-within {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-react-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-react-select[multiple],
.custom-react-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-react-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-react-select::-ms-expand {
  display: none;
}

.custom-react-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-react-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.was-validated .custom-react-select:valid,
.custom-react-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
    #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
    calc(0.75em + 0.375rem);
}

.was-validated .custom-react-select:valid:focus,
.custom-react-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-react-select:valid ~ .valid-feedback,
.was-validated .custom-react-select:valid ~ .valid-tooltip,
.custom-react-select.is-valid ~ .valid-feedback,
.custom-react-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-react-select:invalid,
.custom-react-select.is-invalid {
  border-color: #dc3545;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
    #fff no-repeat center right 1.75rem / calc(1.75em + 1.375rem)
    calc(0.75em + 0.375rem);
}

.was-validated .custom-react-select:invalid:focus,
.custom-react-select.is-invalid:focus,
.custom-react-select.is-invalid:focus-within {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-react-select:invalid ~ .invalid-feedback,
.was-validated .custom-react-select:invalid ~ .invalid-tooltip,
.custom-react-select.is-invalid ~ .invalid-feedback,
.custom-react-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.pf-margin-l-5 {
    margin-left: 5px;
}

.pf-margin-t-0 {
  margin-top: 0 !important;
}
